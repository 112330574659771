import { getLodop } from "@/common/js/LodopFuncs.js";
String.prototype.getByteLen = function () {
  let len = 0;
  for (let i = 0; i < this.length; i++) {
    this.charCodeAt(i) < 256 ? (len += 1) : (len += 2)
  }
  return len
}

function textLeft(str, num, big, bold) {
  if (!num) {
    return ""
  }
  let wNum = str.getByteLen()
  let rStr = ""
  if (big && bold) {
    wNum = wNum * 2
    rStr = '<B><BOLD>' + str + '</BOLD></B>'
  } else if (bold) {
    rStr = '<BOLD>' + str + '</BOLD>'
  } else if (big) {
    wNum = wNum * 2
    rStr = '<B>' + str + '</B>'
  } else {
    rStr = str
  }
  if (wNum < num) {
    let v = num - wNum
    for (let i = 0; i < v; i++) {
      rStr += ' '
    }
  }
  return rStr
}
function textRight(str, num, big, bold) {
  if (!num) {
    return ""
  }
  let wNum = str.getByteLen()
  if (big) {
    wNum = wNum * 2
  }
  let rStr = ""
  if (wNum < num) {
    let v = num - wNum
    for (let i = 0; i < v; i++) {
      rStr += ' '
    }
  }

  if (big && bold) {
    rStr = rStr + '<B><BOLD>' + str + '</BOLD></B>'
  } else if (bold) {
    rStr = rStr + '<BOLD>' + str + '</BOLD>'
  } else if (big) {
    rStr = rStr + '<B>' + str + '</B>'
  } else {
    rStr += str
  }

  return rStr
}
function textCenter(str, num, big, bold) {
  if (!num) {
    return ""
  }
  let wNum = str.getByteLen()
  if (big) {
    wNum = wNum * 2
  }
  let rStr = ""
  if (wNum < num) {
    let v = num - wNum
    let left = Math.floor(v / 2)
    let right = v - left
    for (let i = 0; i < left; i++) {
      rStr += ' '
    }
    if (big && bold) {
      rStr = rStr + '<B><BOLD>' + str + '</BOLD></B>'
    } else if (bold) {
      rStr = rStr + '<BOLD>' + str + '</BOLD>'
    } else if (big) {
      rStr = rStr + '<B>' + str + '</B>'
    } else {
      rStr += str
    }
    for (let i = 0; i < right; i++) {
      rStr += ' '
    }
  } else {
    rStr += str
  }
  return rStr
}

/**
 * 飞鹅打印处理数据格式
 *
 * @param {*} info
 * @param {string} str
 */
const printFE = function (str, info) {
  const toothLabel = [
    0, 18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38
  ]
  let contentStr = "<BOLD></BOLD>"
  info.forEach(topItem => {
    const mould = JSON.parse(str)
    const data = topItem
    const maxW = mould.card.formList[mould.card.index].maxNum
    const list = mould.list
    let rowWidth = 0
    list.forEach((item, index) => {
      if (item.tag == "line") {
        for (let i = 0; i < maxW; i++) {
          contentStr += "-"
        }
        contentStr += "<BR>"
        if (index + 1 == list.length) {
          // contentStr += "<BR><BR><BR>  <CUT>"
          return
        }
      } else if (item.tag == "code") {
        if (item.textItems == "center") {
          contentStr += "<C><BC128_A>" + (data[item.bindValue] ? data[item.bindValue] : ' ') + "</BC128_A></C>"
        } else if (item.textItems == 'right') {
          contentStr += "<RIGHT><BC128_A>" + (data[item.bindValue] ? data[item.bindValue] : ' ') + "</BC128_A></RIGHT>"
        } else {
          contentStr += "<BC128_A>" + (data[item.bindValue] ? data[item.bindValue] : ' ') + "</BC128_A>"
        }
        if (index + 1 == list.length) {
          // contentStr += "<BR><BR><BR>  <CUT>"
          return
        }
      } else if (item.tag == "empty") {
        contentStr += "<BR>"
        if (index + 1 == list.length) {
          // contentStr += "<BR><BR><BR>  <CUT>"
          return
        }
      } else {
        if (item.labelAlign == "center") {
          contentStr += textCenter(item.label, item.labelWidth, item.fontSize == 24, item.bold)
        } else if (item.labelAlign == 'right') {
          contentStr += textRight(item.label, item.labelWidth, item.fontSize == 24, item.bold)
        } else {
          contentStr += textLeft(item.label, item.labelWidth, item.fontSize == 24, item.bold)
        }
        let value = data[item.bindValue] ? data[item.bindValue].toString() : ''
        if (item.bindValue == "tooth" && value.length) {
          let arr = value.split(',')
          value = arr.map(item => toothLabel[item])
          value = value.join(',')
        }
        if (item.textItems == "center") {
          contentStr += textCenter(value, item.width - item.labelWidth, item.fontSize == 24, item.bold)
        } else if (item.textItems == 'right') {
          contentStr += textRight(value, item.width - item.labelWidth, item.fontSize == 24, item.bold)
        } else {
          contentStr += textLeft(value, item.width - item.labelWidth, item.fontSize == 24, item.bold)
        }
        //是否添加换行结尾
        if (index + 1 == list.length) {
          // contentStr += "<BR><BR><BR>   <CUT>"
          return
        }
        if (list[index + 1].tag === "empty" || list[index + 1].tag === "code" || list[index + 1].tag === "line") {
          contentStr += "<BR>"
          rowWidth = 0
        } else {
          rowWidth += item.width
          if (rowWidth >= maxW) {
            contentStr += "<BR>"
            rowWidth = 0

          } else if (rowWidth + list[index + 1].width > maxW) {
            contentStr += "<BR>"
            rowWidth = 0
          }
        }
      }
    })
  })
  return contentStr
}

/**
 *打印卡片
 *
 * @param {*} str
 * @param {*} info
 */
const printCard = function (str, info) {
  return new Promise((resolve, reject) => {
    const list = JSON.parse(str).list
    let htmlStr = `<div style="box-sizing: border-box;width: 324px;height: 207px;position: relative;overflow: hidden;">`
    list.forEach(item => {
      if (item.layout == "heheItem") {
        htmlStr += `<div  style="box-sizing: border-box;width: ${item.width}px; font-size:${item.fontSize}px; display: inline-block;
      line-height: ${item.fontSize + 8}px;overflow: hidden; top:  ${item.top}px; left:  ${item.left}px;left:  ${item.left}px; position: ${item.isFloat ? 'absolute' : 'relative'};" >
      <div <div style="display: flex; align-items: ${item.alignItems};">
        <p class="label" style=" width: ${item.labelWidth}px; text-align:  ${item.labelAlign}; ">${item.label}</p>
        <p class="value" style="flex: 1; text-align:  ${item.textItems};">${info[item.bindValue] ? info[item.bindValue] : ''}</p>
      </div>
    </div>`
      } else if (item.layout == "tooth") {
        const tooth = info['tooth'].split(',')
        const toothLabel = [
          0, 18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38
        ]
        htmlStr += `<div style="width: ${item.width}px; height:${item.height}px; font-size: ${item.fontSize}px; display: inline-block; overflow: hidden;
      top:  ${item.top}px; left:  ${item.left}px; position: ${item.isFloat ? 'absolute' : 'relative'};">
       <div class="y-wei" style="width: ${item.width}px; height:${item.height}px;display: flex;flex-wrap: wrap;">
       <div class="box" style="width: ${item.width / 2}px; height:${item.height / 2}px; 
       padding-right: ${item.lrMargin}px;padding-bottom: ${item.tbMargin}px;align-items: flex-end;justify-content: flex-end;display: flex">`
        for (let i = 1; i < 9; i++) {
          if (tooth.findIndex(tItem => tItem == i) != -1) {
            htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
          }
        }
        htmlStr += `</div><div class="box" style="width: ${item.width / 2}px; height:${item.height / 2}px; 
       padding-left: ${item.lrMargin}px;padding-bottom: ${item.tbMargin}px;align-items: flex-end;display: flex">`
        for (let i = 9; i < 17; i++) {
          if (tooth.findIndex(tItem => tItem == i) != -1) {
            htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
          }
        }
        htmlStr += `</div> <div class="box" style="width: ${item.width / 2}px; height:${item.height / 2}px; 
       padding-right: ${item.lrMargin}px;padding-top: ${item.tbMargin}px;justify-content: flex-end;display: flex">`
        for (let i = 17; i < 25; i++) {
          if (tooth.findIndex(tItem => tItem == i) != -1) {
            htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
          }
        }
        htmlStr += `</div>  <div class="box" style="width: ${item.width / 2}px; height:${item.height / 2}px; 
       padding-left: ${item.lrMargin}px;padding-top: ${item.tbMargin}px;display: flex">`
        for (let i = 25; i < 33; i++) {
          if (tooth.findIndex(tItem => tItem == i) != -1) {
            htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
          }
        }
        htmlStr += `</div>
       </div>
       </div>`
      } else if (item.layout == "tooth2") {
        const tooth = info['tooth'].split(',')
        const toothLabel = [
          0, 8, 7, 6, 5, 4, 3, 2, 1, 1, 2, 3, 4, 5, 6, 7, 8, 8, 7, 6, 5, 4, 3, 2, 1, 1, 2, 3, 4, 5, 6, 7, 8
        ]
        htmlStr += `<div style="width: ${item.width}px; height:${item.height}px; font-size: ${item.fontSize}px; display: inline-block; overflow: hidden;
      top:  ${item.top}px; left:  ${item.left}px; position: ${item.isFloat ? 'absolute' : 'relative'};">
       <div class="y-wei" style="width: ${item.width}px; height:${item.height}px;display: flex;flex-wrap: wrap;">
       <div class="box" style="width: ${item.width / 2}px; height:${item.height / 2}px; 
       padding-right: ${item.lrMargin}px;padding-bottom: ${item.tbMargin}px;align-items: flex-end;justify-content: flex-end;display: flex">`
        for (let i = 1; i < 9; i++) {
          if (tooth.findIndex(tItem => tItem == i) != -1) {
            htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
          }
        }
        htmlStr += `</div><div class="box" style="width: ${item.width / 2}px; height:${item.height / 2}px; 
       padding-left: ${item.lrMargin}px;padding-bottom: ${item.tbMargin}px;align-items: flex-end;display: flex">`
        for (let i = 9; i < 17; i++) {
          if (tooth.findIndex(tItem => tItem == i) != -1) {
            htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
          }
        }
        htmlStr += `</div> <div class="box" style="width: ${item.width / 2}px; height:${item.height / 2}px; 
       padding-right: ${item.lrMargin}px;padding-top: ${item.tbMargin}px;justify-content: flex-end;display: flex">`
        for (let i = 17; i < 25; i++) {
          if (tooth.findIndex(tItem => tItem == i) != -1) {
            htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
          }
        }
        htmlStr += `</div>  <div class="box" style="width: ${item.width / 2}px; height:${item.height / 2}px; 
       padding-left: ${item.lrMargin}px;padding-top: ${item.tbMargin}px;display: flex">`
        for (let i = 25; i < 33; i++) {
          if (tooth.findIndex(tItem => tItem == i) != -1) {
            htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
          }
        }
        htmlStr += `</div>
       </div>
       </div>`
      }
    })
    htmlStr += `</div>`
    Print($(htmlStr)[0], {
      onEnd: function () {
        console.log('onEnd', new Date())
        resolve('onEnd')
      }
    })
  })

}


/**
 *本地打印发货单
 *
 * @param {*} str
 * @param {*} info
 * @param {*} name
 */
const printLocal = function (str, info, name) {

  return new Promise((resolve, reject) => {
    const list = JSON.parse(str).list
    const card = JSON.parse(str).card
    let LODOP = getLodop()
    LODOP.PRINT_INIT("打单");
    LODOP.SET_PRINTER_INDEX(name);
    LODOP.SET_PRINT_PAGESIZE(card.formList[0].transverse ? 2 : 1, card.formList[0].pageWidth * 10, card.formList[0].pageHeight * 10, "LodopCustomPage");
    info.forEach(infoItem => {
      let localHtml = $(`<div id='lodopWrapper' >
      <div id='lodopBox' style="box-sizing: border-box;width: ${card.formList[0].width}px;height:${card.formList[0].height}px;padding: 10px;position: relative;overflow: hidden;"></div></div>`)
      let htmlStr = ``
      list.forEach(item => {
        if (item.layout == "heheItem") {
          htmlStr += `<div style="box-sizing: border-box;width: ${item.width}px; font-size:${item.fontSize}px; display: inline-block;
        line-height: ${item.fontSize + 10}px;overflow: hidden; top:  ${item.top}px; left:  ${item.left}px;margin-left:  ${item.marginLeft}px;margin-top:  ${item.marginTop}px; position: ${item.isFloat ? 'absolute' : 'relative'};" >
        <div style="display: flex; align-items: ${item.alignItems};">
          <p class="label" style="margin-bottom:0;margin-top:0; width: ${item.labelWidth}px; text-align:  ${item.labelAlign}; text-align-last:  ${item.labelAlign == 'justify' ? 'justify' : 'auto'};">${item.label}</p>
          <p class="value" style="margin-bottom:0;margin-top:0;flex: 1;word-break:break-all; text-align:  ${item.textItems};">${infoItem[item.bindValue] ? infoItem[item.bindValue] : ''}</p>
        </div>
      </div>`
        }
        else if (item.layout == "heheLine") {
          htmlStr += `<div style="box-sizing: border-box;width: ${item.width}px; font-size:${item.fontSize}px; display: inline-block;
          line-height: ${item.fontSize + 10}px;height: 18px; overflow: hidden; top:  ${item.top}px; left:  ${item.left}px;margin-left:  ${item.marginLeft}px;margin-top:  ${item.marginTop}px;paddingTop:  ${item.paddingTop}px;paddingBottom:  ${item.paddingBottom}px; position: ${item.isFloat ? 'absolute' : 'relative'};" >
          <div style="display: flex; height: 18px; align-items: center;justify-content:center">
            <div class="label" style="display: flex;margin-bottom:0;margin-top:0; width: ${item.width}px;height: 1px; white-space: nowrap;">`
          for (let i = 0; i < 100; i++) {
            htmlStr += ` <div style="height: 1px;width:1px; background: #000;"></div><div style="height: 1px;width:1px;"></div>`
          }
          htmlStr += `</div>
      </div>
    </div>`
        } else if (item.layout == "heheLineVertical") {
          htmlStr += `<div style="box-sizing: border-box;height: ${item.width}px; font-size:${item.fontSize}px; display: inline-block;
          line-height: ${item.fontSize + 10}px;width: 18px; overflow: hidden; top:  ${item.top}px; left:  ${item.left}px;margin-left:  ${item.marginLeft}px;margin-top:  ${item.marginTop}px;paddingTop:  ${item.paddingTop}px;paddingBottom:  ${item.paddingBottom}px; position: ${item.isFloat ? 'absolute' : 'relative'};" >
          <div style="display: flex; width: 18px; align-items: center;justify-content:center">
            <div class="label" style="margin-bottom:0;margin-top:0; height: ${item.width}px;width:1px; white-space: nowrap;">`
          for (let i = 0; i < 100; i++) {
            htmlStr += ` <div style="height: 1px;width:1px; background: #000;"></div><div style="height: 1px;width:1px;"></div>`
          }
          htmlStr += `</div>
        </div>
      </div>`
        } else if (item.layout == "heheLineSolid") {
          htmlStr += `<div style="box-sizing: border-box;width: ${item.width}px; font-size:${item.fontSize}px; display: inline-block;
          line-height: ${item.fontSize + 10}px;height: 18px; overflow: hidden; top:  ${item.top}px; left:  ${item.left}px;margin-left:  ${item.marginLeft}px;margin-top:  ${item.marginTop}px;paddingTop:  ${item.paddingTop}px;paddingBottom:  ${item.paddingBottom}px; position: ${item.isFloat ? 'absolute' : 'relative'};" >
          <div style="display: flex; height: 18px; align-items: center;justify-content:center">
            <div class="label" style="margin-bottom:0;margin-top:0; width: ${item.width}px;height:1px;  white-space: nowrap;background: #000;"></div>
          </div>
        </div>`
        } else if (item.layout == "heheLineSolidVertical") {
          htmlStr += `<div style="box-sizing: border-box;height: ${item.width}px; font-size:${item.fontSize}px; display: inline-block;
          line-height: ${item.fontSize + 10}px;width: 18px; overflow: hidden; top:  ${item.top}px; left:  ${item.left}px;margin-left:  ${item.marginLeft}px;margin-top:  ${item.marginTop}px;paddingTop:  ${item.paddingTop}px;paddingBottom:  ${item.paddingBottom}px; position: ${item.isFloat ? 'absolute' : 'relative'};" >
          <div style="display: flex; width: 18px; align-items: center;justify-content:center">
            <div class="label" style="margin-bottom:0;margin-top:0; height: ${item.width}px;width:1px;  white-space: nowrap;background: #000;"></div>
          </div>
        </div>`
        } else if (item.layout == "heheCode") {
          htmlStr += `<div style="box-sizing: border-box; display: inline-block;overflow: hidden; top:  ${item.top}px; left:  ${item.left}px;padding-top:  ${item.paddingTop}px;padding-bottom:  ${item.paddingBottom}px; margin-left:  ${item.marginLeft}px;margin-top:  ${item.marginTop}px;position: ${item.isFloat ? 'absolute' : 'relative'};" >
          <svg class="svgcode" id="svgcode"></svg>
        </div>`
        } else if (item.layout == "tooth") {
          const tooth = infoItem['tooth'].split(',')
          const toothLabel = [
            0, 18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38
          ]
          htmlStr += `<div style="width: ${item.width + 4}px; height:${item.height + 4}px; font-size: ${item.fontSize}px; display: inline-block; overflow: hidden;
        top:  ${item.top}px; left:  ${item.left}px;margin-left:  ${item.marginLeft}px;margin-top:  ${item.marginTop}px; position: ${item.isFloat ? 'absolute' : 'relative'};">
         <div class="y-wei" style="width: ${item.width + 4}px; height:${item.height + 4}px;display: flex;flex-wrap: wrap;align-content: flex-start;">
         <div class="box" style="width: ${item.width / 2 - 4}px; height:${item.height / 2 - 4}px;  overflow: hidden;border-bottom: 1px dotted;border-right: 1px dotted;
         padding-right: ${item.lrMargin}px;padding-bottom: ${item.tbMargin}px;align-items: flex-end;justify-content: flex-end;display: flex">`
          for (let i = 1; i < 9; i++) {
            if (tooth.findIndex(tItem => tItem == i) != -1) {
              htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
            }
          }
          htmlStr += `</div><div class="box" style="width: ${item.width / 2 - 4}px; height:${item.height / 2 - 4}px; overflow: hidden;border-bottom: 1px dotted;
         padding-left: ${item.lrMargin}px;padding-bottom: ${item.tbMargin}px;align-items: flex-end;display: flex">`
          for (let i = 9; i < 17; i++) {
            if (tooth.findIndex(tItem => tItem == i) != -1) {
              htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
            }
          }
          htmlStr += `</div> <div class="box" style="width: ${item.width / 2 - 4}px; height:${item.height / 2 - 4}px; overflow: hidden;border-right: 1px dotted;
         padding-right: ${item.lrMargin}px;padding-top: ${item.tbMargin}px;justify-content: flex-end;display: flex">`
          for (let i = 17; i < 25; i++) {
            if (tooth.findIndex(tItem => tItem == i) != -1) {
              htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
            }
          }
          htmlStr += `</div>  <div class="box" style="width: ${item.width / 2 - 4}px; height:${item.height / 2 - 4}px; overflow: hidden;
         padding-left: ${item.lrMargin}px;padding-top: ${item.tbMargin}px;display: flex;align-items: flex-start;">`
          for (let i = 25; i < 33; i++) {
            if (tooth.findIndex(tItem => tItem == i) != -1) {
              htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
            }
          }
          htmlStr += `</div>
         </div>
         </div>`
        } else if (item.layout == "tooth2") {
          const tooth = infoItem['tooth'].split(',')
          const toothLabel = [
            0, 8, 7, 6, 5, 4, 3, 2, 1, 1, 2, 3, 4, 5, 6, 7, 8, 8, 7, 6, 5, 4, 3, 2, 1, 1, 2, 3, 4, 5, 6, 7, 8
          ]
          htmlStr += `<div style="width: ${item.width + 4}px; height:${item.height + 4}px; font-size: ${item.fontSize}px; display: inline-block; overflow: hidden;
        top:  ${item.top}px; left:  ${item.left}px;margin-left:  ${item.marginLeft}px;margin-top:  ${item.marginTop}px; position: ${item.isFloat ? 'absolute' : 'relative'};">
         <div class="y-wei" style="width: ${item.width + 4}px; height:${item.height + 4}px;display: flex;flex-wrap: wrap;align-content: flex-start;">
         <div class="box" style="width: ${item.width / 2 - 4}px; height:${item.height / 2 - 4}px;  overflow: hidden;border-bottom: 1px dashed;border-right: 1px dashed;
         padding-right: ${item.lrMargin}px;padding-bottom: ${item.tbMargin}px;align-items: flex-end;justify-content: flex-end;display: flex">`
          for (let i = 1; i < 9; i++) {
            if (tooth.findIndex(tItem => tItem == i) != -1) {
              htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
            }
          }
          htmlStr += `</div><div class="box" style="width: ${item.width / 2 - 4}px; height:${item.height / 2 - 4}px; overflow: hidden;border-bottom: 1px dashed;
         padding-left: ${item.lrMargin}px;padding-bottom: ${item.tbMargin}px;align-items: flex-end;display: flex">`
          for (let i = 9; i < 17; i++) {
            if (tooth.findIndex(tItem => tItem == i) != -1) {
              htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
            }
          }
          htmlStr += `</div> <div class="box" style="width: ${item.width / 2 - 4}px; height:${item.height / 2 - 4}px; overflow: hidden;border-right: 1px dashed;
         padding-right: ${item.lrMargin}px;padding-top: ${item.tbMargin}px;justify-content: flex-end;display: flex">`
          for (let i = 17; i < 25; i++) {
            if (tooth.findIndex(tItem => tItem == i) != -1) {
              htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
            }
          }
          htmlStr += `</div>  <div class="box" style="width: ${item.width / 2 - 4}px; height:${item.height / 2 - 4}px; overflow: hidden;
         padding-left: ${item.lrMargin}px;padding-top: ${item.tbMargin}px;display: flex;align-items: flex-start;">`
          for (let i = 25; i < 33; i++) {
            if (tooth.findIndex(tItem => tItem == i) != -1) {
              htmlStr += `<div style="margin-right: ${item.itemMargin}px;">${toothLabel[i]}</div>`
            }
          }
          htmlStr += `</div>
         </div>
         </div>`
        }
      })
      // htmlStr = `<div style="page-break-before: always; box-sizing: border-box;width: ${card.formList[0].width}px;height:${card.formList[0].height}px;padding: 10px;position: relative;overflow: hidden;">` + htmlStr + `</div>`
      // console.log(htmlStr)
      let el = $(htmlStr)
      if (el.find(".svgcode")[0]) {
        JsBarcode(el.find(".svgcode")[0], infoItem.order_sn, {
          // format: "pharmacode",
          width: 1,
          height: 30,
          fontSize: 16,
          displayValue: true
        });
      }
      localHtml.find("#lodopBox").append(el)
      // $("body").append(localHtml)
      LODOP.NewPage();
      LODOP.ADD_PRINT_HTM(0, 0, '100%', '100%', localHtml.html());
    })
    LODOP.PRINT();
    // LODOP.PREVIEW();



    // Print(localHtml[0], {
    //   onEnd: function () {
    //     console.log('onEnd', new Date())
    //     resolve('onEnd')
    //   }
    // })
  })
}
export default {
  printFE,
  printCard,
  printLocal
};
