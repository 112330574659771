<script>
import draggable from 'vuedraggable'
import render from '@/common/js/render'

const components = {
  itemBtns(h, element, index, parent) {
    const { copyItem, deleteItem } = this.$listeners
    return [
      <span class="drawing-item-copy" title="复制" onClick={event => {
        copyItem(element, parent); event.stopPropagation()
      }}>
        <i class="el-icon-copy-document" />
      </span>,
      <span class="drawing-item-delete" title="删除" onClick={event => {
        deleteItem(index, parent); event.stopPropagation()
      }}>
        <i class="el-icon-delete" />
      </span>
    ]
  }
}
const layouts = {
  colFormItem(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered'
    return (
      <el-col span={element.span} class={className}
        nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
        <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
          label={element.label} required={element.required}>
          <render key={element.renderKey} conf={element} onInput={event => {
            this.$set(element, 'defaultValue', event)
          }} />
        </el-form-item>
        {components.itemBtns.apply(this, arguments)}
      </el-col>
    )
  },
  rowFormItem(h, element, index, parent) {
    const { activeItem } = this.$listeners
    const className = this.activeId === element.formId ? 'drawing-row-item active-from-item' : 'drawing-row-item'
    let child = renderChildren.apply(this, arguments)
    if (element.type === 'flex') {
      child = <el-row type={element.type} justify={element.justify} align={element.align}>
        {child}
      </el-row>
    }
    return (
      <el-col span={element.span}>
        <el-row gutter={element.gutter} class={className}
          nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
          <span class="component-name">{element.componentName}</span>
          <draggable list={element.children} animation={340} group="componentsGroup" class="drag-wrapper">
            {child}
          </draggable>
          {components.itemBtns.apply(this, arguments)}
        </el-row>
      </el-col>
    )
  },
  heheItem(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    let style = {
      width: element.width + 'px',
      // height: element.height + 'px',
      // paddingLeft: element.paddingLeft + 'px',
      // paddingRight: element.paddingRight + 'px',
      // paddingTop: element.paddingTop + 'px',
      // paddingBottom: element.paddingBottom + 'px',
      // marginLeft: element.marginLeft + 'px',
      // marginRight: element.marginRight + 'px',
      // marginTop: element.marginTop + 'px',
      // marginBottom: element.marginBottom + 'px',
      marginLeft: element.marginLeft + 'px',
      marginTop: element.marginTop + 'px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#000',
      fontSize: element.fontSize + 'px',
      lineHeight: (element.fontSize + 8) + 'px',
      display: 'inline-block',
      overflow: 'hidden',
      top: element.top + 'px',
      left: element.left + 'px',
      position: element.isFloat ? 'absolute' : 'relative'
    }
    let styleLabel = {
      width: element.labelWidth + 'px',
      textAlign: element.labelAlign,
      textAlignLast: element.labelAlign == 'justify' ? 'justify' : 'auto',
      marginRight: element.labelRight + 'px',
    }
    let styleValue = {
      flex: 1,
      textAlign: element.textItems,
      wordBreak: 'break-all'
      // maxWidth:`calc(100% - ${element.labelWidth}px - 5px)`
    }
    let styleBox = {
      display: 'flex',
      alignItems: element.alignItems,
    }
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <div style={styleBox}>
          <p class="label" style={styleLabel}>{element.label}</p>
          <p class="value" id={element.bindValue} style={styleValue}>{element.default}</p>
        </div>
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
  tooth(h, element, index, parent) {
    const { activeItem } = this.$listeners

    // {
    //     title: '牙位',
    //     tag: 'tooth',
    //     width: 200,
    //     height: 50,
    //     fontSize: 12,
    //     tbMargin: 5,
    //     lrMargin:5,
    //     itemMargin:2,
    //     layout: 'tooth',
    //     bindValue: "tooth",
    //     default: '5,12,28,21,8',
    //     changeTag: true,
    //     isFloat: false,
    //     top: 0,
    //     left: 0
    // },

    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    let style = {
      width: element.width + 'px',
      height: element.height + 'px',
      fontSize: element.fontSize + 'px',
      marginLeft: element.marginLeft + 'px',
      marginTop: element.marginTop + 'px',
      display: 'inline-block',
      overflow: 'hidden',
      top: element.top + 'px',
      left: element.left + 'px',
      position: element.isFloat ? 'absolute' : 'relative'
    }
    let boxGroupStyle = {
      width: element.width + 'px',
      height: element.height + 'px',
    }
    let boxStyle = {
      width: (element.width / 2) + 'px',
      height: (element.height / 2) + 'px',
      paddingTop: element.tbMargin + 'px',
      paddingBottom: element.tbMargin + 'px',
      paddingLeft: element.lrMargin + 'px',
      paddingRight: element.lrMargin + 'px',
    }
    let itemStyle = {
      marginRight: element.itemMargin + 'px'
    }

    // let styleLabel = {
    //   width: element.labelWidth + 'px',
    //   textAlign: element.labelAlign,
    //   marginRight: element.labelRight + 'px',
    // }
    // let styleValue = {
    //   flex: 1,
    //   textAlign: element.textItems,
    //   // maxWidth:`calc(100% - ${element.labelWidth}px - 5px)`
    // }
    // let styleBox = {
    //   display: 'flex',
    //   alignItems: element.alignItems,
    // }


    return (
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <div style={boxGroupStyle} class="y-wei">
          <div style={boxStyle} class="box">
            <div style={itemStyle} class="item active">
              {14}
            </div>
            <div style={itemStyle} class="item active">
              {11}
            </div>
          </div>
          <div style={boxStyle} class="box">
            <div style={itemStyle} class="item active">
              {24}
            </div>
          </div>
          <div style={boxStyle} class="box">
            {44}
          </div>
          <div style={boxStyle} class="box">
            {34}
          </div>
        </div>
        {components.itemBtns.apply(this, arguments)}
      </div>

      // <div class="y-wei">
      //           <div class="box" v-for="(item, index) in ywList" :key="index">
      //             <div
      //               class="item"
      //               :class="{
      //                 active: isSelectTable(subItem.id, scope.row.tooth),
      //               }"
      //               v-for="subItem in item"
      //               :key="subItem.id"
      //             >
      //               {{ subItem.lable }}
      //             </div>
      //           </div >
      //         </div >
    )
  },
  tooth2(h, element, index, parent) {
    const { activeItem } = this.$listeners

    // {
    //     title: '牙位',
    //     tag: 'tooth',
    //     width: 200,
    //     height: 50,
    //     fontSize: 12,
    //     tbMargin: 5,
    //     lrMargin:5,
    //     itemMargin:2,
    //     layout: 'tooth',
    //     bindValue: "tooth",
    //     default: '5,12,28,21,8',
    //     changeTag: true,
    //     isFloat: false,
    //     top: 0,
    //     left: 0
    // },

    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    let style = {
      width: element.width + 'px',
      height: element.height + 'px',
      fontSize: element.fontSize + 'px',
      display: 'inline-block',
      overflow: 'hidden',
      marginLeft: element.marginLeft + 'px',
      marginTop: element.marginTop + 'px',
      top: element.top + 'px',
      left: element.left + 'px',
      position: element.isFloat ? 'absolute' : 'relative'
    }
    let boxGroupStyle = {
      width: element.width + 'px',
      height: element.height + 'px',
    }
    let boxStyle = {
      width: (element.width / 2) + 'px',
      height: (element.height / 2) + 'px',
      paddingTop: element.tbMargin + 'px',
      paddingBottom: element.tbMargin + 'px',
      paddingLeft: element.lrMargin + 'px',
      paddingRight: element.lrMargin + 'px',
    }
    let itemStyle = {
      marginRight: element.itemMargin + 'px'
    }

    // let styleLabel = {
    //   width: element.labelWidth + 'px',
    //   textAlign: element.labelAlign,
    //   marginRight: element.labelRight + 'px',
    // }
    // let styleValue = {
    //   flex: 1,
    //   textAlign: element.textItems,
    //   // maxWidth:`calc(100% - ${element.labelWidth}px - 5px)`
    // }
    // let styleBox = {
    //   display: 'flex',
    //   alignItems: element.alignItems,
    // }


    return (
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <div style={boxGroupStyle} class="y-wei">
          <div style={boxStyle} class="box">
            <div style={itemStyle} class="item active">
              {7}
            </div>
            <div style={itemStyle} class="item active">
              {1}
            </div>
          </div>
          <div style={boxStyle} class="box">
            <div style={itemStyle} class="item active">
              {2}
            </div>
          </div>
          <div style={boxStyle} class="box">
            {4}
          </div>
          <div style={boxStyle} class="box">
            {3}
          </div>
        </div>
        {components.itemBtns.apply(this, arguments)}
      </div>

      // <div class="y-wei">
      //           <div class="box" v-for="(item, index) in ywList" :key="index">
      //             <div
      //               class="item"
      //               :class="{
      //                 active: isSelectTable(subItem.id, scope.row.tooth),
      //               }"
      //               v-for="subItem in item"
      //               :key="subItem.id"
      //             >
      //               {{ subItem.lable }}
      //             </div>
      //           </div >
      //         </div >
    )
  },
  feItem(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    // if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered'
    let oneItemWidth = (this.formConf.formList[this.formConf.index].width - 20) / this.formConf.formList[this.formConf.index].maxNum
    oneItemWidth = oneItemWidth.toFixed(3)
    let style = {
      width: element.width * oneItemWidth + 'px',
      height: element.fontSize + 10 + 'px',
      fontSize: element.fontSize + 'px',
      lineHeight: element.fontSize + 10 + 'px',
      display: 'inline-block',
      overflow: 'hidden',
    }
    let styleLabel = {
      width: element.labelWidth * oneItemWidth + 'px',
      textAlign: element.labelAlign,
      fontWeight: element.bold ? 'bold' : 'normal'
    }
    let styleValue = {
      flex: 1,
      textAlign: element.textItems,
      fontWeight: element.bold ? 'bold' : 'normal'
      // maxWidth:`calc(100% - ${element.labelWidth}px - 5px)`
    }
    let styleBox = {
      display: 'flex',
      // alignItems: element.alignItems,
    }
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <div style={styleBox}>
          <p class="label" style={styleLabel}>{element.label}</p>
          <p class="value" id={element.bindValue} style={styleValue}>{element.default}</p>
        </div>
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
  heheCode(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered'
    let style = {
      width: '132px',
      paddingLeft: element.paddingLeft + 'px',
      paddingRight: element.paddingRight + 'px',
      paddingTop: element.paddingTop + 'px',
      paddingBottom: element.paddingBottom + 'px',
      marginLeft: element.marginLeft + 'px',
      marginRight: element.marginRight + 'px',
      marginTop: element.marginTop + 'px',
      marginBottom: element.marginBottom + 'px',
      borderWidth: element.borderWidth + 'px',
      borderStyle: 'solid',
      borderColor: element.isBorder ? '#000' : "transparent",
      display: 'inline-block',
      overflow: 'hidden',
      top: element.top + 'px',
      left: element.left + 'px',
      position: element.isFloat ? 'absolute' : 'relative'
    }
    let styleBox = {
      display: 'block',
      width: '132px',
      height: "72px"
    }
    const src = require('@/assets/code.png')
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <img style={styleBox} src={src} alt="" />
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
  heheLine(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    let style = {
      width: element.width + 'px',
      // height: element.height + 'px',
      // paddingLeft: element.paddingLeft + 'px',
      // paddingRight: element.paddingRight + 'px',
      marginLeft: element.marginLeft + 'px',
      marginTop: element.marginTop + 'px',
      // marginLeft: element.marginLeft + 'px',
      // marginRight: element.marginRight + 'px',
      // marginTop: element.marginTop + 'px',
      // marginBottom: element.marginBottom + 'px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#000',
      fontSize: element.fontSize + 'px',
      lineHeight: (element.fontSize + 8) + 'px',
      height: '18px',
      display: 'inline-block',
      overflow: 'hidden',
      top: element.top + 'px',
      left: element.left + 'px',
      position: element.isFloat ? 'absolute' : 'relative'
    }
    let styleLabel = {
      width: element.width + 'px',
      borderTopWidth: '1px',
      borderStyle: 'dotted',
      borderColor: '#000',
      whiteSpace: 'nowrap'
    }
    let styleBox = {
      display: 'flex',
      height: '18px',
      alignItems: 'center',
      justifyContent: 'center',
    }
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <div style={styleBox}>
          <p class="label" style={styleLabel}></p>
        </div>
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
  heheLineSolid(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    let style = {
      width: element.width + 'px',
      // height: element.height + 'px',
      // paddingLeft: element.paddingLeft + 'px',
      // paddingRight: element.paddingRight + 'px',
      marginLeft: element.marginLeft + 'px',
      marginTop: element.marginTop + 'px',
      // marginLeft: element.marginLeft + 'px',
      // marginRight: element.marginRight + 'px',
      // marginTop: element.marginTop + 'px',
      // marginBottom: element.marginBottom + 'px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#000',
      fontSize: element.fontSize + 'px',
      lineHeight: (element.fontSize + 8) + 'px',
      height: '18px',
      display: 'inline-block',
      overflow: 'hidden',
      top: element.top + 'px',
      left: element.left + 'px',
      position: element.isFloat ? 'absolute' : 'relative',
      transform: element.routate ? 'rotate(90deg) translate(50%, 50%)' : ""
    }
    let styleLabel = {
      width: element.width + 'px',
      height: '1px',
      background: '#000',
      whiteSpace: 'nowrap'
    }
    let styleBox = {
      display: 'flex',
      height: '18px',
      alignItems: 'center',
    }
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <div style={styleBox}>
          <div class="label" style={styleLabel}></div>
        </div>
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
  heheLineSolidVertical(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item vertical' : 'drawing-item vertical'
    let style = {
      height: element.width + 'px',
      // height: element.height + 'px',
      // paddingLeft: element.paddingLeft + 'px',
      // paddingRight: element.paddingRight + 'px',
      marginLeft: element.marginLeft + 'px',
      marginTop: element.marginTop + 'px',
      // marginLeft: element.marginLeft + 'px',
      // marginRight: element.marginRight + 'px',
      // marginTop: element.marginTop + 'px',
      // marginBottom: element.marginBottom + 'px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#000',
      fontSize: element.fontSize + 'px',
      lineHeight: (element.fontSize + 8) + 'px',
      with: '18px',
      display: 'inline-block',
      overflow: 'hidden',
      top: element.top + 'px',
      left: element.left + 'px',
      position: element.isFloat ? 'absolute' : 'relative',
    }
    let styleLabel = {
      height: element.width + 'px',
      width: '1px',
      background: '#000',
      whiteSpace: 'nowrap'
    }
    let styleBox = {
      display: 'flex',
      width: '18px',
      alignItems: 'center',
      justifyContent: 'center',
    }
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <div style={styleBox}>
          <div class="label" style={styleLabel}></div>
        </div>
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
  heheLineVertical(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item vertical' : 'drawing-item vertical'
    let style = {
      height: element.width + 'px',
      // height: element.height + 'px',
      // paddingLeft: element.paddingLeft + 'px',
      // paddingRight: element.paddingRight + 'px',
      marginLeft: element.marginLeft + 'px',
      marginTop: element.marginTop + 'px',
      // marginLeft: element.marginLeft + 'px',
      // marginRight: element.marginRight + 'px',
      // marginTop: element.marginTop + 'px',
      // marginBottom: element.marginBottom + 'px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#000',
      fontSize: element.fontSize + 'px',
      lineHeight: (element.fontSize + 8) + 'px',
      with: '18px',
      display: 'inline-block',
      overflow: 'hidden',
      top: element.top + 'px',
      left: element.left + 'px',
      position: element.isFloat ? 'absolute' : 'relative',
    }
    let styleLabel = {
      height: element.width + 'px',
      borderLeftWidth: '1px',
      borderStyle: 'dotted',
      borderColor: '#000',
      whiteSpace: 'nowrap'
    }
    let styleBox = {
      display: 'flex',
      width: '18px',
      alignItems: 'center',
      justifyContent: 'center',
    }
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <div style={styleBox}>
          <div class="label" style={styleLabel}></div>
        </div>
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
}

function renderChildren(h, element, index, parent) {
  if (!Array.isArray(element.children)) return null
  return element.children.map((el, i) => {
    const layout = layouts[el.layout]
    if (layout) {
      return layout.call(this, h, el, i, element.children)
    }
    return layoutIsNotFound()
  })
}

function layoutIsNotFound() {
  throw new Error(`没有与${this.element.layout}匹配的layout`)
}

export default {
  components: {
    render,
    draggable
  },
  props: [
    'element',
    'index',
    'drawingList',
    'activeId',
    'formConf'
  ],
  render(h) {
    const layout = layouts[this.element.layout]
    if (layout) {
      return layout.call(this, h, this.element, this.index, this.drawingList)
    }

    return layoutIsNotFound()
  }
}
</script>
