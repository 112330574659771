<template>
  <div class="right-board">
    <el-tabs v-model="currentTab" class="center-tabs">
      <el-tab-pane label="组件属性" name="field" />
      <el-tab-pane label="卡片属性" name="form" />
    </el-tabs>
    <div class="field-box">
      <!-- <a class="document-link" target="_blank" :href="documentLink" title="查看组件文档">
          <i class="el-icon-link" />
        </a> -->
      <el-scrollbar class="right-scrollbar">
        <!-- 组件属性 -->
        <el-form v-show="currentTab === 'field' && showField" size="small" label-width="90px">
          <!-- <el-form-item v-if="activeData.vModel !== undefined" label="字段名">
            <el-input v-model="activeData.vModel" placeholder="请输入字段名（v-model）" />
          </el-form-item> -->
          <el-form-item v-if="activeData.label !== undefined && (activeData.tag == 'div' || activeData.tag == 'label')"
            label="标签名称">
            <el-input v-model="activeData.label" placeholder="标签名称" />
          </el-form-item>
          <el-form-item v-if="activeData.default !== undefined && (activeData.tag == 'div' || activeData.tag == 'value')"
            label="占位值">
            <el-input placeholder="请输入占位值" v-model="activeData.default" />
          </el-form-item>
          <el-form-item
            v-if="activeData.bindValue !== undefined && (activeData.tag == 'div' || activeData.tag == 'value')"
            label="绑定字段名">
            <el-select v-model="activeData.bindValue" placeholder="请选择字段名">
              <el-option v-for="item in dataCard" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.width !== undefined" label="组件宽度">
            <el-slider v-model="activeData.width" :max="formConf.formList[formConf.index].width - 20" :min="1" />
          </el-form-item>
          <el-form-item v-if="activeData.height !== undefined" label="组件高度">
            <el-slider v-model="activeData.height" :max="192" :min="1" :marks="{ 96: '' }" />
          </el-form-item>
          <el-form-item v-if="activeData.tbMargin !== undefined" label="牙位线上下间距">
            <el-slider v-model="activeData.tbMargin" :max="20" :min="1" :marks="{ 10: '' }" />
          </el-form-item>
          <el-form-item v-if="activeData.lrMargin !== undefined" label="牙位线左右间距">
            <el-slider v-model="activeData.lrMargin" :max="20" :min="1" :marks="{ 10: '' }" />
          </el-form-item>
          <el-form-item v-if="activeData.itemMargin !== undefined" label="单个牙位左右间距">
            <el-slider v-model="activeData.itemMargin" :max="20" :min="1" :marks="{ 10: '' }" />
          </el-form-item>
          <!-- <el-form-item v-if="activeData.height !== undefined" label="组件高度">
            <el-input v-model.number="activeData.height" type="number" placeholder="请输入组件高度" />
          </el-form-item> -->
          <el-form-item v-if="activeData.fontSize !== undefined" label="字体大小">
            <el-slider v-model="activeData.fontSize" :max="40" :min="10" :marks="{ 25: '' }" />
          </el-form-item>
          <el-form-item
            v-if="activeData.bold !== undefined && (activeData.tag == 'div' || activeData.tag == 'label' || activeData.tag == 'value')"
            label="是否加粗">
            <el-switch v-model="activeData.bold" />
          </el-form-item>

          <!-- <el-form-item v-if="activeData.isBorder !== undefined" label="显示边框">
            <el-switch v-model="activeData.isBorder" />
          </el-form-item>
          <el-form-item v-if="activeData.borderWidth !== undefined" label="边框宽度">
            <el-input v-model.number="activeData.borderWidth" type="number" placeholder="请输入边框宽度" />
          </el-form-item> -->

          <el-form-item
            v-if="activeData.labelWidth !== undefined && (activeData.tag == 'div' || activeData.tag == 'label')"
            label="标签宽度">
            <el-slider v-model="activeData.labelWidth" :max="formConf.formList[formConf.index].width - 20" :min="1" />
            <!-- <el-input v-model.number="activeData.labelWidth" type="number" placeholder="请输入标签宽度" /> -->
          </el-form-item>
          <el-form-item
            v-if="activeData.labelAlign !== undefined && (activeData.tag == 'div' || activeData.tag == 'label')"
            label="标签排列">
            <el-radio-group v-model="activeData.labelAlign">
              <el-radio-button label="left">左对齐</el-radio-button>
              <el-radio-button label="center">居中</el-radio-button>
              <el-radio-button label="right">右对齐</el-radio-button>
              <el-radio-button label="justify">分散对齐</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="activeData.textItems !== undefined && (activeData.tag == 'div' || activeData.tag == 'value' || activeData.tag == 'code')"
            label="绑定值排列">
            <el-radio-group v-model="activeData.textItems">
              <el-radio-button label="left">左对齐</el-radio-button>
              <el-radio-button label="center">居中</el-radio-button>
              <el-radio-button label="right">右对齐</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item v-if="activeData.labelRight !== undefined" label="标签间隙">
            <el-input v-model.number="activeData.labelRight" type="number" placeholder="请输入标签间隙" />
          </el-form-item>
          <el-form-item v-if="activeData.alignItems !== undefined" label="垂直排列">
            <el-radio-group v-model="activeData.alignItems">
              <el-radio-button label="flex-start">顶部</el-radio-button>
              <el-radio-button label="center">居中</el-radio-button>
              <el-radio-button label="flex-end">底部</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="activeData.paddingLeft !== undefined" label="左内边距">
            <el-input v-model.number="activeData.paddingLeft" type="number" placeholder="请输入左内边距" />
          </el-form-item>
          <el-form-item v-if="activeData.paddingRight !== undefined" label="右内边距">
            <el-input v-model.number="activeData.paddingRight" type="number" placeholder="请输入右内边距" />
          </el-form-item>
          <el-form-item v-if="activeData.marginLeft !== undefined" label="左外边距">
            <el-input v-model.number="activeData.marginLeft" type="number" placeholder="请输入左外边距" />
          </el-form-item>
          <el-form-item v-if="activeData.marginRight !== undefined" label="右外边距">
            <el-input v-model.number="activeData.marginRight" type="number" placeholder="请输入右外边距" />
          </el-form-item>
          <el-form-item v-if="activeData.marginTop !== undefined" label="上外边距">
            <el-input v-model.number="activeData.marginTop" type="number" placeholder="请输入上外边距" />
          </el-form-item>
          <el-form-item v-if="activeData.marginBottom !== undefined" label="下外边距">
            <el-input v-model.number="activeData.marginBottom" type="number" placeholder="请输入下外边距" />
          </el-form-item> -->
          <el-form-item v-if="activeData.marginTop !== undefined" label="上边距">
            <el-input v-model.number="activeData.marginTop" type="number" placeholder="请输入上边距" />
          </el-form-item>
          <el-form-item v-if="activeData.marginLeft !== undefined" label="左边距">
            <el-input v-model.number="activeData.marginLeft" type="number" placeholder="请输入左边距" />
          </el-form-item>
          <el-form-item v-if="activeData.isFloat !== undefined" label="浮动组件">
            <el-switch v-model="activeData.isFloat" />
          </el-form-item>
          <el-form-item v-if="activeData.top !== undefined" label="浮动上定位">
            <el-input v-model.number="activeData.top" type="number" placeholder="请输入浮动上定位" />
          </el-form-item>
          <el-form-item v-if="activeData.left !== undefined" label="浮动左定位">
            <el-input v-model.number="activeData.left" type="number" placeholder="请输入浮动左定位" />
          </el-form-item>

        </el-form>
        <!-- 表单属性 -->
        <el-form v-show="currentTab === 'form'" size="small" label-width="90px">
          <!-- <el-form-item label="表单名">
            <el-input v-model="formConf.formRef" placeholder="请输入表单名（ref）" />
          </el-form-item>
          <el-form-item label="表单模型">
            <el-input v-model="formConf.formModel" placeholder="请输入数据模型" />
          </el-form-item>
          <el-form-item label="校验模型">
            <el-input v-model="formConf.formRules" placeholder="请输入校验模型" />
          </el-form-item> -->
          <!-- <el-form-item label="表单尺寸">
            <el-radio-group v-model="formConf.index">
              <el-radio-button :label="index" v-for="(item, index) in formConf.formList" :key="index">
                {{ item.title }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item> -->
          <!--<el-form-item label="标签对齐">
            <el-radio-group v-model="formConf.labelPosition">
              <el-radio-button label="left">
                左对齐
              </el-radio-button>
              <el-radio-button label="right">
                右对齐
              </el-radio-button>
              <el-radio-button label="top">
                顶部对齐
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签宽度">
            <el-input-number v-model="formConf.labelWidth" placeholder="标签宽度" />
          </el-form-item>
          <el-form-item label="栅格间隔">
            <el-input-number v-model="formConf.gutter" :min="0" placeholder="栅格间隔" />
          </el-form-item>
          <el-form-item label="禁用表单">
            <el-switch v-model="formConf.disabled" />
          </el-form-item>
          <el-form-item label="表单按钮">
            <el-switch v-model="formConf.formBtns" />
          </el-form-item>
          <el-form-item label="显示未选中组件边框">
            
            <el-switch v-model="formConf.unFocusedComponentBorder" />
          </el-form-item> -->
          <el-form-item label="表单宽度">
            <el-input-number v-model="formConf.formList[formConf.index].width" placeholder="表单宽度" />
          </el-form-item>
          <el-form-item label="表单高度">
            <el-input-number v-model="formConf.formList[formConf.index].height" placeholder="表单高度" />
          </el-form-item>
          <el-form-item label="纸张宽度（mm）">
            <el-input-number v-model="formConf.formList[formConf.index].pageWidth" placeholder="纸张宽度（mm）" />
          </el-form-item>
          <el-form-item label="纸张高度（mm）">
            <el-input-number v-model="formConf.formList[formConf.index].pageHeight" placeholder="纸张高度（mm）" />
          </el-form-item>
          <el-form-item label="横向打印">
            <el-switch v-model="formConf.formList[formConf.index].transverse" />
          </el-form-item>
          <!-- <el-form-item label="卡片边距">
            <el-input-number v-model="formConf.padding" placeholder="卡片边距" />
          </el-form-item> -->
        </el-form>
      </el-scrollbar>
    </div>

    <treeNode-dialog :visible.sync="dialogVisible" title="添加选项" @commit="addNode" />
    <icons-dialog :visible.sync="iconsVisible" :current="activeData[currentIconModel]" @select="setIcon" />
  </div>
</template>
  
<script>
import { isArray } from 'util'
import TreeNodeDialog from '../TreeNodeDialog'
import { isNumberStr } from '@/common/js/index'
import IconsDialog from '../IconsDialog'
import {
  inputComponents,
  selectComponents,
  layoutComponents,
  dataCard
} from '@/common/js/configHtml'

const dateTimeFormat = {
  date: 'yyyy-MM-dd',
  week: 'yyyy 第 WW 周',
  month: 'yyyy-MM',
  year: 'yyyy',
  datetime: 'yyyy-MM-dd HH:mm:ss',
  daterange: 'yyyy-MM-dd',
  monthrange: 'yyyy-MM',
  datetimerange: 'yyyy-MM-dd HH:mm:ss'
}

export default {
  components: {
    TreeNodeDialog,
    IconsDialog
  },
  props: ['showField', 'activeData', 'formConf'],
  data() {
    return {
      dataCard,
      currentTab: 'field',
      currentNode: null,
      dialogVisible: false,
      iconsVisible: false,
      currentIconModel: null,
      dateTypeOptions: [
        {
          label: '日(date)',
          value: 'date'
        },
        {
          label: '周(week)',
          value: 'week'
        },
        {
          label: '月(month)',
          value: 'month'
        },
        {
          label: '年(year)',
          value: 'year'
        },
        {
          label: '日期时间(datetime)',
          value: 'datetime'
        }
      ],
      dateRangeTypeOptions: [
        {
          label: '日期范围(daterange)',
          value: 'daterange'
        },
        {
          label: '月范围(monthrange)',
          value: 'monthrange'
        },
        {
          label: '日期时间范围(datetimerange)',
          value: 'datetimerange'
        }
      ],
      colorFormatOptions: [
        {
          label: 'hex',
          value: 'hex'
        },
        {
          label: 'rgb',
          value: 'rgb'
        },
        {
          label: 'rgba',
          value: 'rgba'
        },
        {
          label: 'hsv',
          value: 'hsv'
        },
        {
          label: 'hsl',
          value: 'hsl'
        }
      ],
      justifyOptions: [
        {
          label: 'start',
          value: 'start'
        },
        {
          label: 'end',
          value: 'end'
        },
        {
          label: 'center',
          value: 'center'
        },
        {
          label: 'space-around',
          value: 'space-around'
        },
        {
          label: 'space-between',
          value: 'space-between'
        }
      ],
      layoutTreeProps: {
        label(data, node) {
          return data.componentName || `${data.label}: ${data.vModel}`
        }
      }
    }
  },
  computed: {
    documentLink() {
      return (
        this.activeData.document
        || 'https://element.eleme.cn/#/zh-CN/component/installation'
      )
    },
    dateOptions() {
      if (
        this.activeData.type !== undefined
        && this.activeData.tag === 'el-date-picker'
      ) {
        if (this.activeData['start-placeholder'] === undefined) {
          return this.dateTypeOptions
        }
        return this.dateRangeTypeOptions
      }
      return []
    },
    tagList() {
      return [
        {
          label: '输入型组件',
          options: inputComponents
        },
        {
          label: '选择型组件',
          options: selectComponents
        }
      ]
    }
  },
  methods: {
    addReg() {
      this.activeData.regList.push({
        pattern: '',
        message: ''
      })
    },
    addSelectItem() {
      this.activeData.options.push({
        label: '',
        value: ''
      })
    },
    addTreeItem() {
      ++this.idGlobal
      this.dialogVisible = true
      this.currentNode = this.activeData.options
    },
    renderContent(h, { node, data, store }) {
      return (
        <div class="custom-tree-node">
          <span>{node.label}</span>
          <span class="node-operation">
            <i on-click={() => this.append(data)}
              class="el-icon-plus"
              title="添加"
            ></i>
            <i on-click={() => this.remove(node, data)}
              class="el-icon-delete"
              title="删除"
            ></i>
          </span>
        </div>
      )
    },
    append(data) {
      if (!data.children) {
        this.$set(data, 'children', [])
      }
      this.dialogVisible = true
      this.currentNode = data.children
    },
    remove(node, data) {
      const { parent } = node
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
    },
    addNode(data) {
      this.currentNode.push(data)
    },
    setOptionValue(item, val) {
      item.value = isNumberStr(val) ? +val : val
    },
    setDefaultValue(val) {
      if (Array.isArray(val)) {
        return val.join(',')
      }
      if (['string', 'number'].indexOf(val) > -1) {
        return val
      }
      if (typeof val === 'boolean') {
        return `${val}`
      }
      return val
    },
    onDefaultValueInput(str) {
      if (isArray(this.activeData.defaultValue)) {
        // 数组
        this.$set(
          this.activeData,
          'defaultValue',
          str.split(',').map(val => (isNumberStr(val) ? +val : val))
        )
      } else if (['true', 'false'].indexOf(str) > -1) {
        // 布尔
        this.$set(this.activeData, 'defaultValue', JSON.parse(str))
      } else {
        // 字符串和数字
        this.$set(
          this.activeData,
          'defaultValue',
          isNumberStr(str) ? +str : str
        )
      }
    },
    onSwitchValueInput(val, name) {
      if (['true', 'false'].indexOf(val) > -1) {
        this.$set(this.activeData, name, JSON.parse(val))
      } else {
        this.$set(this.activeData, name, isNumberStr(val) ? +val : val)
      }
    },
    setTimeValue(val, type) {
      const valueFormat = type === 'week' ? dateTimeFormat.date : val
      this.$set(this.activeData, 'defaultValue', null)
      this.$set(this.activeData, 'value-format', valueFormat)
      this.$set(this.activeData, 'format', val)
    },
    spanChange(val) {
      this.formConf.span = val
    },
    multipleChange(val) {
      this.$set(this.activeData, 'defaultValue', val ? [] : '')
    },
    dateTypeChange(val) {
      this.setTimeValue(dateTimeFormat[val], val)
    },
    rangeChange(val) {
      this.$set(
        this.activeData,
        'defaultValue',
        val ? [this.activeData.min, this.activeData.max] : this.activeData.min
      )
    },
    rateTextChange(val) {
      if (val) this.activeData['show-score'] = false
    },
    rateScoreChange(val) {
      if (val) this.activeData['show-text'] = false
    },
    colorFormatChange(val) {
      this.activeData.defaultValue = null
      this.activeData['show-alpha'] = val.indexOf('a') > -1
      this.activeData.renderKey = +new Date() // 更新renderKey,重新渲染该组件
    },
    openIconsDialog(model) {
      this.iconsVisible = true
      this.currentIconModel = model
    },
    setIcon(val) {
      this.activeData[this.currentIconModel] = val
    },
    tagChange(tagIcon) {
      let target = inputComponents.find(item => item.tagIcon === tagIcon)
      if (!target) target = selectComponents.find(item => item.tagIcon === tagIcon)
      this.$emit('tag-change', target)
    }
  }
}
</script>
  
<style lang="scss" scoped>
.right-board {
  width: 350px;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 3px;

  .field-box {
    position: relative;
    height: calc(100vh - 42px);
    box-sizing: border-box;
    overflow: hidden;
  }

  .el-scrollbar {
    height: 100%;
  }
}

.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;

  & .close-btn {
    cursor: pointer;
    color: #f56c6c;
  }

  & .el-input+.el-input {
    margin-left: 4px;
  }
}

.select-item+.select-item {
  margin-top: 4px;
}

.select-item.sortable-chosen {
  border: 1px dashed #409eff;
}

.select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #777;
}

.option-drag {
  cursor: move;
}

.time-range {
  .el-date-editor {
    width: 227px;
  }

  ::v-deep .el-icon-time {
    display: none;
  }
}

.document-link {
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  top: 0;
  left: 0;
  cursor: pointer;
  background: #409eff;
  z-index: 1;
  border-radius: 0 0 6px 0;
  text-align: center;
  line-height: 26px;
  color: #fff;
  font-size: 18px;
}

.node-label {
  font-size: 14px;
}

.node-icon {
  color: #bebfc3;
}
</style>
  